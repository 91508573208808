export interface ResolvablePromise<T> extends Promise<T> {
    resolve(value: T): void;
    reject(reason: any): void; // eslint-disable-line @typescript-eslint/no-explicit-any
}
export default function resolvablePromise<T>(): ResolvablePromise<T> {
    const b = {
        resolve: null! as (v: T) => void,
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        reject: null! as (r: any) => void,
    };
    const a = new Promise((resolve, reject) => {
        b.resolve = resolve;
        b.reject = reject;
    });
    //@ts-ignore
    a.resolve = b.resolve;
    //@ts-ignore
    a.reject = b.reject;
    return a as ResolvablePromise<T>;
}
